import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/Layout.js";

export default ({ data }) => {
  return (
    <Layout>
      <h1 style={{ textAlign: "center" }}>
        Hi! Welcome to my corner of the internet.{" "}
        <span role="img" aria-labelledby="smiley">
          😁
        </span>
      </h1>
      <p style={{ textAlign: "center", lineHeight: 1.6 }}>
        There's not much here right now, but hopefully that will change soon!
        <br /> For now, you can find my resume&nbsp;
        <Link to="/resume/" style={{ textDecoration: "underline" }}>
          here
        </Link>
        !
      </p>
    </Layout>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
